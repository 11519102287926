var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c(
          "div",
          { staticClass: "kt-portlet" },
          [
            _vm._m(0),
            _vm.loading
              ? _c("PortletLoader")
              : [
                  _c("div", { staticClass: "kt-portlet__body" }, [
                    _c("div", { staticClass: "kt-section mb-0" }, [
                      _c("div", { staticClass: "kt-section__title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.user.school.externalSchoolId) +
                            " " +
                            _vm._s(_vm.user.school.schoolName) +
                            " "
                        ),
                      ]),
                      _c("div", { staticClass: "kt-section__desc pb-3" }, [
                        _vm._v(
                          " You are currently in the " +
                            _vm._s(_vm.user.school.name) +
                            " school term. "
                        ),
                        _c("br"),
                        _vm._v(
                          " You can add or select school terms below, and you can also set a default term for the school campus. "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-section mb-0" }, [
                      _c(
                        "div",
                        { staticClass: "kt-widget2" },
                        _vm._l(_vm.schoolTerms, function (term) {
                          return _c(
                            "div",
                            {
                              key: "terms_" + term.schoolTermId,
                              staticClass: "kt-widget2__item",
                              class: {
                                "kt-widget2__item--primary":
                                  term.schoolTermId ==
                                  _vm.user.school.schoolTermId,
                                //'kt-widget2__item--danger': !term.finalized,
                                "kt-widget2__item--warning":
                                  term.finalized && !term.current,
                              },
                            },
                            [
                              _c("div", {
                                staticClass:
                                  "kt-widget2__checkbox ml-1 p-0 pl-4 pb-2",
                              }),
                              _c(
                                "div",
                                { staticClass: "kt-widget2__info pt-2" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "kt-widget2__title",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.openTerm(term.schoolTermId)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(term.schoolYear) +
                                          " - " +
                                          _vm._s(term.schoolYearPlusOne) +
                                          " School Year "
                                      ),
                                      _c("span", { staticClass: "ml-2 mr-2" }, [
                                        _vm._v(" - "),
                                      ]),
                                      _c("span", [
                                        _vm._v(
                                          "Term " + _vm._s(term.schoolTerm)
                                        ),
                                      ]),
                                      term.current
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "kt-badge kt-badge--success kt-badge--inline ml-5",
                                            },
                                            [
                                              _vm._v(
                                                " DEFAULT TERM FOR ALL USERS "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  !term.finalized
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-muted kt-font-danger",
                                        },
                                        [
                                          _vm._v(
                                            " This term needs to be finalized before it can be used. "
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(term.termRange) + " "
                                          ),
                                        ]
                                      ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-widget2__actions" },
                                [
                                  term.schoolTermId !=
                                  _vm.user.school.schoolTermId
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-clean btn-sm",
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openTerm(
                                                term.schoolTermId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "la la-chevron-right",
                                          }),
                                          _vm._v(" Select "),
                                        ]
                                      )
                                    : _c(
                                        "router-link",
                                        {
                                          staticClass: "btn btn-sm btn-primary",
                                          attrs: {
                                            to: { name: "ConfigureTerm" },
                                            href: "#",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openTerm(
                                                term.schoolTermId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("i", { staticClass: "la la-cog" }),
                                          _vm._v(" Configure "),
                                        ]
                                      ),
                                  false
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "kt-badge kt-badge--inline kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold pl-4 pr-4",
                                        },
                                        [_vm._v(" CURRENT TERM ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "kt-portlet__foot" }, [
                    _c(
                      "div",
                      {
                        staticClass: "kt-form__actions kt-form__actions--right",
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.showTermModal.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v(" Create New Term ")]
                            ),
                          ]),
                          _c("div", { staticClass: "col-6" }),
                        ]),
                      ]
                    ),
                  ]),
                  _c("b-modal", {
                    ref: "term-create-modal",
                    scopedSlots: _vm._u([
                      {
                        key: "modal-header",
                        fn: function ({ close }) {
                          return [
                            _c("h5", [_vm._v("Create New School Term")]),
                            _c("button", {
                              staticClass: "close",
                              attrs: {
                                type: "button",
                                "data-dismiss": "modal",
                                "aria-label": "Close",
                              },
                              on: {
                                click: function ($event) {
                                  return close()
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: "default",
                        fn: function () {
                          return [
                            _c(
                              "form",
                              { staticClass: "kt-form kt-form--label-right" },
                              [
                                _c("div", { staticClass: "modal-body" }, [
                                  _c("div", { staticClass: "form-group row" }, [
                                    _c("div", { staticClass: "col-lg-6" }, [
                                      _c("label", [_vm._v("Year:")]),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.schoolYear,
                                            expression: "schoolYear",
                                          },
                                        ],
                                        staticClass: "form-control",
                                        attrs: { type: "number" },
                                        domProps: { value: _vm.schoolYear },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.schoolYear = $event.target.value
                                          },
                                        },
                                      }),
                                    ]),
                                    _c("div", { staticClass: "col-lg-6" }, [
                                      _c("label", {}, [_vm._v("Term:")]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.schoolTerm,
                                              expression: "schoolTerm",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.schoolTerm = $event.target
                                                .multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            },
                                          },
                                        },
                                        _vm._l(_vm.possibleTerms, function (t) {
                                          return _c(
                                            "option",
                                            {
                                              key: `new_term_${t}`,
                                              domProps: { value: t },
                                            },
                                            [_vm._v(" " + _vm._s(t) + " ")]
                                          )
                                        }),
                                        0
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "modal-footer",
                        fn: function () {
                          return [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success pull-right",
                                class: {
                                  "kt-spinner kt-spinner--sm kt-spinner--light":
                                    _vm.saving,
                                },
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.createNewTerm()
                                  },
                                },
                              },
                              [_vm._v(" Save School Term ")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" School Configuration "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }