<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        School Configuration
                    </h3>
                </div>
            </div>
            <PortletLoader v-if="loading" />
            <template v-else>
                <div class="kt-portlet__body">
                    <div class="kt-section mb-0">
                        <div class="kt-section__title">
                            {{ user.school.externalSchoolId }} {{ user.school.schoolName }}
                        </div>
                        <div class="kt-section__desc pb-3">
                            You are currently in the {{ user.school.name }} school term.
                            <br>
                            You can add or select school terms below, and you can also set a default term for the school campus.
                        </div>
                    </div>

                    <div class="kt-section mb-0">
                        <div class="kt-widget2">
                            <div
                                v-for="term in schoolTerms"
                                :key="'terms_' + term.schoolTermId"
                                class="kt-widget2__item"
                                :class="{
                                    'kt-widget2__item--primary': term.schoolTermId == user.school.schoolTermId,
                                    //'kt-widget2__item--danger': !term.finalized,
                                    'kt-widget2__item--warning': term.finalized && !term.current,
                                }"
                            >
                                <div class="kt-widget2__checkbox ml-1 p-0 pl-4 pb-2" />
                                <div class="kt-widget2__info pt-2">
                                    <a
                                        href="#"
                                        class="kt-widget2__title"
                                        @click.stop.prevent="openTerm(term.schoolTermId)"
                                    >
                                        {{ term.schoolYear }} - {{ term.schoolYearPlusOne }} School Year

                                        <span class="ml-2 mr-2"> - </span>
                                        <span>Term {{ term.schoolTerm }}</span>
                                        <span
                                            v-if="term.current"
                                            class="kt-badge kt-badge--success kt-badge--inline ml-5"
                                        > DEFAULT TERM FOR ALL USERS </span>
                                    </a>

                                    <span
                                        v-if="!term.finalized"
                                        class="text-muted kt-font-danger"
                                    > This term needs to be finalized before it can be used. </span>

                                    <span
                                        v-else
                                        class="text-muted"
                                    >
                                        {{ term.termRange }}
                                    </span>
                                </div>
                                <div class="kt-widget2__actions">
                                    <a
                                        v-if="term.schoolTermId != user.school.schoolTermId"
                                        href="#"
                                        class="btn btn-clean btn-sm"
                                        @click.stop.prevent="openTerm(term.schoolTermId)"
                                    >
                                        <i class="la la-chevron-right" />
                                        Select
                                    </a>
                                    <router-link
                                        v-else
                                        :to="{ name: 'ConfigureTerm' }"
                                        href="#"
                                        class="btn btn-sm btn-primary"
                                        @click.stop.prevent="openTerm(term.schoolTermId)"
                                    >
                                        <i class="la la-cog" />
                                        Configure
                                    </router-link>
                                    <span
                                        v-if="false"
                                        class="kt-badge kt-badge--inline kt-badge--unified-brand kt-badge--lg kt-badge--rounded kt-badge--bold pl-4 pr-4"
                                    > CURRENT TERM </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="kt-portlet__foot">
                    <div class="kt-form__actions kt-form__actions--right">
                        <div class="row">
                            <div class="col-6">
                                <button
                                    class="btn btn-success"
                                    @click.stop.prevent="showTermModal"
                                >
                                    Create New Term
                                </button>
                            </div>
                            <div class="col-6" />
                        </div>
                    </div>
                </div>

                <b-modal ref="term-create-modal">
                    <template #modal-header="{ close }">
                        <h5>Create New School Term</h5>
                        <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            @click="close()"
                        />
                    </template>
                    <template #default>
                        <form class="kt-form kt-form--label-right">
                            <div class="modal-body">
                                <div class="form-group row">
                                    <div class="col-lg-6">
                                        <label>Year:</label>
                                        <input
                                            v-model="schoolYear"
                                            class="form-control"
                                            type="number"
                                        >
                                    </div>
                                    <div class="col-lg-6">
                                        <label class="">Term:</label>
                                        <select
                                            v-model="schoolTerm"
                                            class="form-control"
                                        >
                                            <option
                                                v-for="t in possibleTerms"
                                                :key="`new_term_${t}`"
                                                :value="t"
                                            >
                                                {{ t }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </template>
                    <template #modal-footer>
                        <button
                            type="button"
                            class="btn btn-success pull-right"
                            :class="{
                                'kt-spinner kt-spinner--sm kt-spinner--light': saving,
                            }"
                            @click="createNewTerm()"
                        >
                            Save School Term
                        </button>
                    </template>
                </b-modal>
            </template>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import moment from 'moment';
import { mapState } from 'vuex';
import PortletLoader from '../components/PortletLoader.vue';
import * as network from '../network';

import Types from '../store/Types';

export default {
    name: 'SchoolTerms',
    components: {
        PortletLoader,
    },
    data() {
        return {
            currentSchoolTermId: 0,
            saving: false,
            loading: true,
            schoolYear: null,
            schoolTerm: 1,
            possibleTerms: [1, 2, 3, 4, 7],
            schoolTerms: [],
            school: {
                schoolId: null,
                externalSchoolId: null,
                schoolName: null,
                author: null,
                contactEmail: null,
                contactUserId: null,
                supportLink: null,
                requireTwoFactorAuth: null,
                deleted: null,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
        }),
        currentTerm() {
            return this.schoolTerms.find((t) => t.current) || null;
        },
        isValidSchoolEmail() {
            const schoolEmail = (this.school.contactEmail || '').toLowerCase();
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(schoolEmail);
        },
        thisSchoolDomain() {
            if (!this.isValidSchoolEmail) return null;
            if (!this.school.contactEmail) return '';
            return this.school.contactEmail.split('@')[1].toLowerCase();
        },
        mySchoolDomain() {
            return this.userName.split('@')[1];
        },
        isValidSchoolDomain() {
            if (!this.isValidSchoolEmail) return false;
            return this.mySchoolDomain == this.thisSchoolDomain;
        },
        isSchoolNameValid() {
            return (this.school.schoolName || '').length > 0;
        },
        isSchoolYearValid() {
            return (this.schoolTerm.year || '').length == 4;
        },
        isSchoolTermValid() {
            return ['1', '2', '3', '4', '7'].indexOf(this.schoolTerm.term) > -1;
        },
        isValidContactEmail() {
            let { contactEmail } = this.school;
            contactEmail = contactEmail || '';
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(contactEmail.toLowerCase());
        },
        isFormValid() {
            if (!this.isSchoolNameValid) return false;
            if (!this.isValidContactEmail) return false;
            if (!this.isValidSchoolDomain) return false;
            return true;
        },
    },
    mounted() {
        if (this.currentTerm) {
            this.currentSchoolTermId = this.currentTerm.schoolTermId;
        }
        this.schoolYear = this.user.school.schoolYear;
        this.populate();
    },
    methods: {
        populate() {
            const { school } = this.user;
            const { schoolId, schoolTermId } = school;
            const v = this;
            v.loading = true;

            // apply user to local data
            this.school = {
                ...this.school,
                ...school,
            };

            network.schoolTerms.listForSchool({ url: { schoolId, schoolTermId } }, (err, res) => {
                v.loading = false;
                if (err) return v.showError(err);
                const { schoolTerms } = res;
                const sortOn = (a, b) => (`${a.schoolYear}_${a.schoolTerm}` > `${b.schoolYear}_${b.schoolTerm}` ? 1 : -1);

                this.schoolTerms = schoolTerms
                    .map((t) => {
                        const term = t;
                        const termStart = term.termStart.substring(0, 10);
                        const termEnd = term.termEnd.substring(0, 10);
                        const formattedStart = moment(termStart, 'YYYY-MM-DD').format('ddd MMM Do YYYY');
                        const formattedEnd = moment(termEnd, 'YYYY-MM-DD').format('ddd MMM Do YYYY');
                        term.termRange = `${formattedStart} - ${formattedEnd}`;
                        term.termStart = termStart;
                        term.termEnd = termEnd;
                        term.schoolYearPlusOne = parseInt(t.schoolYear, 10) + 1;
                        return term;
                    })
                    .sort(sortOn);
            });
        },
        createNewTerm() {
            const v = this;

            const exists = v.schoolTerms.find((t) => t.schoolYear == v.schoolYear && t.schoolTerm == v.schoolTerm && !t.deleted);
            if (exists) return v.showError('Error saving, this term already exists');
            if (v.saving) return;
            v.saving = true;

            const schoolTermSettings = {
                externalSchoolId: v.user.school.externalSchoolId,
                schoolYear: v.schoolYear,
                schoolTerm: v.schoolTerm,
                termStart: `${v.schoolYear}-09-01`,
                termEnd: `${parseInt(v.schoolYear, 10) + 1}-06-30`,
                current: false,
                finalized: false,
                deleted: false,

                // these will be set to default true, since it's a new term
                adminLocked: false,
                communicationEnabled: true,
                anecdotalsEnabled: true,
                gradingEnabled: true,
                encryptionEnabled: true,
                reportCardsEnabled: true,
                reportingEnabled: true,
                googleSetting: v.$store.state.user.school.googleSetting,
                communicationPlan: 'trial',
            };

            const { schoolId, schoolTermId } = v.user.school;
            const action = 'create';
            const params = {
                url: { schoolId, schoolTermId, action },
                body: { schoolTermSettings },
            };
            network.schoolTerms.createTerm(params, (err) => {
                v.saving = false;
                if (err) {
                    v.showError(err);
                }
                this.populate();
            });
        },
        isCurrent(schoolTermId) {
            return this.user.school.schoolTermId !== schoolTermId;
        },
        openTerm(schoolTermId) {
            this.$store.commit(Types.mutations.SET_SCHOOL_TERM, schoolTermId);
            this.$router.push({ name: 'SchoolTerms' });
        },
        showTermModal() {
            this.$refs['term-create-modal'].show();
        },
    },
};
</script>
